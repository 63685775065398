import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/storage'

export const firebaseConfig = {
  apiKey: "AIzaSyDlPLuTxoEHM454BsmDvoMBGUKswIxhaJo",
  authDomain: "m-genio.firebaseapp.com",
  databaseURL: "https://m-genio.firebaseio.com",
  projectId: "m-genio",
  storageBucket: "m-genio.appspot.com",
  messagingSenderId: "762891374666",
  appId: "1:762891374666:web:7c0c659b5c42971254d6b3",
  measurementId: "G-JQ3F1SR5R9"
}

firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
export const analytics = firebase.analytics()
export const functions = firebase.functions()
