<template>
  <form ref="form" @submit.prevent="recaptcha">
    <base-info-card :title="title" :subtitle="subtitle" space="4" color="primary" />

    <base-text-field label="Name" name="name" :rules="nameRules" required />

    <base-text-field label="Email" name="email" :rules="[emailRules.required, emailRules.valid]" required valid />

    <base-text-field label="Subject" name="subject" />

    <base-textarea class="mb-2" label="Your message" name="message" :rules="nameRules" required />

    <v-row class="d-flex pl-4 mb-16" align="center">


      <base-btn type="submit" :color="!theme.isDark ? 'accent' : 'white'" outlined target="_blank">
        Send message
      </base-btn>



      <v-alert v-if="showSuccess" class="ml-12" dense type="success">
        Your message was sent.
      </v-alert>
    </v-row>
  </form>
</template>

<style sass>
.v-alert {
  margin-bottom: 0px;
}
</style>
<script>
import emailjs from "@emailjs/browser";

export default {
  name: "BaseContactForm",

  // Injected from the Vuetify Themeable mixin
  inject: ["theme"],

  props: {
    subtitle: String,
    title: {
      type: String,
      default: "SEND US YOUR MESSAGE"
    }
  },
  data: () => ({
    showSuccess: false,
    email: '',
    nameRules: [(v) => !!v || 'Required'],
    emailRules: {
      required: (v) => !!v || 'E-mail is required',
      valid: (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    },

  }),
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      if (token) this.sendEmail()

    },

    sendEmail() {
      var formData = new FormData(this.$refs.form)
      var email = formData.get('email');
      if (!/.+@.+\..+/.test(email)) {
        console.log('not valid email')
        return;
      }
      emailjs.init("B9NXCSsjPHplp7XA8");
      emailjs
        .sendForm(
          "service_u70e1x3",
          "template_ds1bzgi",
          this.$refs.form,
          "B9NXCSsjPHplp7XA8"
        )
        .then(
          result => {
            console.log("Success!", result.text);
            this.showSuccess = true;
          },
          error => {
            console.log("FAILED...", error.text);
          }
        );
    }
  }
};
</script>
