<template>
  <div>
    <v-card :height="height" :width="width" class="pa-4 mb-4 rounded-lg" flat tile v-bind="$attrs" v-on="$listeners"
      elevation="5" @click="$emit('click')">
      <slot />
      <base-img v-if="src" contain :aspect-ratio="1.4" :src="src" />
      <base-title v-if="title" :title="title" class="text-uppercase pt-2" size="text-subtitle-1" space="0" />

      <base-subtitle v-if="subtitle" :title="subtitle" />
    </v-card>
  </div>
</template>


<script>
export default {
  name: "ImageCard",

  inheritAttrs: false,

  props: {
    height: [Number, String],
    width: [Number, String],
    subtitle: String,
    title: String,
    src: String,
  }
};
</script>

