<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card title="KEEP IN TOUCH WITH US" pace="6">
        We are in the business of bringing ideas to life and identifying practical solutions to our clients' problems.
        We'd love
        to hear about your ideas or the problems you face and work out a solution for you. Drop us a line and begin your
        journey
        with M Genio today.
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: "BaseBusinessInfo",

  props: { dark: Boolean },

  data: () => ({
    business: [
      {
        icon: "mdi-map-marker-outline",
        title: "Address",
        text: "1240 Rockwell Ave. Suite 3A<br>Cleveland, Ohio 44114<br><br>2500 Packard Ste. 101<br>Ann Arbor, MI 48103"
      },
      {
        icon: "mdi-cellphone",
        title: "Phone",
        text: "(216) 282 7530"
      },
      {
        icon: "mdi-email",
        title: "Email",
        text: "contact@mgenio.com"
      }
    ]
  })
};
</script>
