import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMq from 'vue-mq'
import './plugins'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, {
  siteKey: '6LdW-wokAAAAAOJj89BmKxgNWj9373MnrsTOlXcy', loaderOptions: {
    autoHideBadge: true
  }
})
Vue.config.productionTip = false
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    xs: 600,
    sm: 960,
    md: 1264,
    lg: 1904,
    xl: Infinity
  }
})



new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
