<template>
  <div>
    <slot name="user" :user="user"></slot>
  </div>
</template>

<script>
import { auth } from '../../firebase/firebase'
import { ref } from 'vue'

export default {
  setup() {
    console.log('FirebaseUser component initialized')
    const user = ref(null)

    const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        console.log('User is signed in')

        const result = await firebaseUser.getIdTokenResult()

        user.value = firebaseUser
      } else {
        console.log('User has signed out')
        user.value = null
      }
    })

    return {
      user,
      unsubscribe
    }
  },
  destroyed() {
    this.unsubscribe()
  },
}
</script>
