<template>
  <component
    :is="tag"
    :class="classes"
    :style="styles"
    class="base-body"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- Only use v-html for user data -->
    <div v-if="text" v-text="text" />

    <div v-else-if="html" v-html="html" />

    <slot v-else />
  </component>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";

export default {
  name: "BaseBody",

  mixins: [Heading],

  inject: ["theme"],

  props: {
    html: String,
    maxWidth: {
      type: [Number, String],
      default: undefined
    },
    space: {
      type: [Number, String],
      default: 10
    },
    tag: {
      type: String,
      default: "p"
    },
    size: {
      type: String,
      default: "text-body-1"
    },
    mobileSize: {
      type: String,
      default: "text-body-1"
    },
    mobileBreakpoint: {
      type: [Number, String],
      default: 768
    },
    text: String
  },

  computed: {
    classes() {
      return [
        this.fontSize,
        "grey--text",
        this.theme.isDark ? "text--lighten-1" : "text--darken-1",
        `text-${this.heading.align}`,
        `mb-${this.space}`
      ];
    },
    fontSize() {
      return this.$vuetify.breakpoint.width >= this.mobileBreakpoint
        ? this.size
        : this.mobileSize;
    },
    styles() {
      return {
        maxWidth: `${this.maxWidth}px`,
        letterSpacing:
          this.fontSize == "text-h6" ? "0.06em !important" : undefined
      };
    }
  }
};
</script>
