<template>
  <div>
    <v-card :height="height" :width="width" :class="
      $mq === 'xs' || $mq === 'sm'
        ? 'pa-4 rounded-lg d-flex flex-column'
        : 'pa-4 rounded-lg d-flex flex-column'
    " flat tile v-bind="$attrs" v-on="$listeners" elevation="5" @click="$emit('click')">
      <slot />
      <base-title v-if="title" :title="title" class="text-uppercase pt-2" size="text-subtitle-1" space="2" />

      <base-subtitle v-if="subtitle" :title="subtitle" :color="!theme.isDark ? 'gray' : 'white'" />
    </v-card>
  </div>
</template>


<script>
export default {
  name: "BaseCard",

  inject: ["theme"],

  inheritAttrs: false,

  props: {
    height: [Number, String],
    width: [Number, String],
    subtitle: String,
    title: String
  }
};
</script>

