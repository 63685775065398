<template>
  <v-theme-provider :dark="dark">
    <div>
      <!-- <base-img
        :src="@/assets/zero-logo-dark.svg')"
        contain
        max-width="128"
        min-height="78"
        width="100%"
      /> -->

      <base-title size="text-body-1" space="4" title="OUR PROMISE" weight="regular" />

      <base-body>
        We are committed to providing superior solutions to our clients-from start-ups to large corporations, and
        everything in
        between.
      </base-body>

      <!-- <base-btn class="mb-12" color="white" outlined :to="{ name: 'About' }">
        More Info
      </base-btn> -->
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: "BaseInfo",

  props: {
    title: String,
    dark: Boolean
  },

  data: () => ({
    business: [
      {
        icon: "mdi-map-marker-outline",
        title: "Address",
        text:
          "1240 Rockwell Ave. Suite 3A<br>Cleveland, Ohio 44114<br><br>2500 Packard Ste. 101<br>Ann Arbor, MI 48103"
      },
      {
        icon: "mdi-cellphone",
        title: "Phone",
        text: "(216) 282 7530"
      },
      {
        icon: "mdi-email",
        title: "Email",
        text: "contact@mgenio.com"
      }
    ]
  })
};
</script>
