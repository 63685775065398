// Imports
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/admin",
      name: "Admin",
      component: () => import("@/layouts/admin/Index.vue"),
      children: [
        {
          path: "manage",
          name: "Manage",
          component: () => import("@/views/admin/manage/Index.vue")
        },
        {
          path: "manage/reviews",
          name: "Reviews",
          component: () => import("@/views/admin/manage/Reviews.vue")
        },
        {
          path: "manage/hero",
          name: "Heros",
          component: () => import("@/views/admin/manage/hero/Versions.vue")
        },
        {
          path: "dashboard",
          name: "Dashboard",
          component: () => import("@/views/admin/dashboard/Index.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/layouts/home/Index.vue"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/home/Index.vue")
        },
        {
          path: "about",
          name: "About",
          component: () => import("@/views/about/Index.vue"),
          meta: { src: require("@/assets/team-meeting.jpg") }
        },
        {
          path: "news",
          name: "News",
          component: () => import("@/views/news/Index.vue"),
          meta: { src: require("@/assets/news.jpg") }
        },
        {
          path: "news/:slug",
          name: "Article",
          component: () => import("@/views/news/Article.vue"),
          meta: { src: require("@/assets/article.jpg") }
        },
        {
          path: "marketing",
          name: "Marketing",
          component: () => import("@/views/marketing/Index.vue"),
          meta: { src: require("@/assets/marketing.jpg") }
        },
        {
          path: "our_work",
          name: "Our Work",
          component: () => import("@/views/gallery/Index.vue"),
          meta: { src: require("@/assets/programmer-focused-on-code.jpg") }
        },
        {
          path: "gallery/:project",
          name: "Project",
          component: () => import("@/views/gallery/Project.vue"),
          meta: { src: require("@/assets/project.jpg") }
        },
        {
          path: "pricing-plans",
          name: "Pricing",
          component: () => import("@/views/pricing-plans/Index.vue"),
          meta: { src: require("@/assets/pricing.jpg") }
        },
        {
          path: "contact-us",
          name: "Contact",
          component: () => import("@/views/contact-us/Index.vue"),
          meta: { src: require("@/assets/contactus.png") }
        },
        {
          path: "pro",
          name: "Pro",
          component: () => import("@/views/pro/Index.vue"),
          meta: { src: require("@/assets/pro.jpg") }
        },
        {
          path: "kitchen-sink",
          name: "Sink",
          component: () => import("@/views/sink/Index.vue"),
          meta: { src: require("@/assets/sink.jpg") }
        },
        {
          path: "*",
          name: "FourOhFour",
          component: () => import("@/views/404/Index.vue")
        }
      ]
    }
  ]
});

export default router;
